<template>
  <div>
    <!-- search input -->
    <b-progress
      v-if="searching"
      :value="barValue"
      max="100"
    />
    <section id="techops-topbox">
      <b-card :title="'Operations Record - Invoice ' + inputInvoiceNumber">
        <b-overlay
          :show="searching"
          rounded="sm"
        >
          <b-row>
            <b-col cols="5">
              <b-form @submit.prevent>
                <b-input-group
                  class="input-group-merge"
                  style="width: 50%"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="searchbar"
                    v-model="invoiceSearch"
                    placeholder="Search an Invoice"
                    @keypress="isNumber($event)"
                    @keyup.enter="openInvoice()"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CheckIcon"
                      @click="openInvoice()"
                    />
                  </b-input-group-append>
                </b-input-group>
                {{ message }}
              </b-form>
              <div
                v-if="dataOperations.timeModified"
                class="d-flex align-items-center mt-2 ml-2"
              >
                {{
                  (dataOperations.timeModified + "Z")
                    | moment("MMMM Do YYYY, h:mm:ss a")
                }}
              </div>
              <div
                v-if="dataOperations.timeModified"
                class="font-small-1 mb-1 ml-2"
              >
                <strong>Last updated</strong>
                {{ (dataOperations.timeModified + "Z") | moment("from") }}<span v-if="dataOperations.updatedBy"> by
                  {{ dataOperations.updatedBy }}</span>
              </div>
            </b-col>
            <b-col
              cols="7"
              class="text-center pt-0"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-progress
                    :max="inputNumProducts"
                    variant="success"
                    class="progress-bar-success"
                  >
                    <b-progress-bar :value="filledSerials.length">
                      <strong>{{ filledSerials.length }} / {{ inputNumProducts }}</strong>
                    </b-progress-bar></b-progress>
                </b-col>
                <b-col>
                  <b-avatar
                    rounded="sm"
                    :variant="status1"
                    class="mx-1"
                  >1 </b-avatar><br>Waiting
                </b-col>
                <b-col>
                  <b-avatar
                    rounded="sm"
                    :variant="status2"
                    class="mx-1"
                  >2 </b-avatar><br>Building
                </b-col>
                <b-col>
                  <b-avatar
                    rounded="sm"
                    :variant="status3"
                    class="mx-1"
                  >3 </b-avatar><br>Complete
                </b-col>
                <b-col>
                  <b-avatar
                    rounded="sm"
                    :variant="status4"
                    class="mx-1"
                  >4 </b-avatar><br>Approved
                </b-col>
                <b-col>
                  <b-avatar
                    rounded="sm"
                    :variant="status5"
                    class="mx-1"
                  >5 </b-avatar><br>Shipped
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </section>
    <section>
      <b-card class="mt-n1 px-3">
        <b-overlay
          :show="searching"
          rounded="sm"
        >
          <b-row>
            <b-col cols="5">
              <div
                v-if="disableBuildTech"
                class="ml-1 mt-1"
              >
                {{ dataOperations.builtBy }}
              </div>
              <b-input-group v-if="!disableBuildTech">
                <b-form-select
                  v-model="inputBuildTech"
                  :options="dataTechs.results"
                  text-field="fullName"
                  value-field="UID"
                />
                <b-input-group-append
                  v-if="!disableBuildTech"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    @click="inputBuildTech = 'TECH-00000040'"
                  />
                </b-input-group-append>
              </b-input-group>

              <div class="font-small-2 ml-1">
                Built By
              </div>
              <div align="center">
                <b-button
                  v-if="dataOperations.status === 'Ready to Build'"
                  variant="outline-primary"
                  @click="dataOperations.status = 'Building'"
                >
                  START BUILDING
                </b-button>
                <b-button
                  v-if="dataOperations.status === 'Building' && serials[0].ID !== 0"
                  variant="outline-primary"
                  @click="dataOperations.status = 'Ready to Approve'"
                >
                  MARK FOR APPROVAL
                </b-button>
                <b-button
                  v-if="dataOperations.status === 'Building' && serials[0].ID === 0"
                  variant="outline-primary"
                  disabled
                >
                  MARK FOR APPROVAL (requires at least 1 serial)
                </b-button>
                <b-button
                  v-if="dataOperations.status === 'Ready to Approve'"
                  variant="outline-primary"
                  @click="dataOperations.status = 'Building'"
                >
                  NOT READY FOR APPROVAL
                </b-button>
              </div>
            </b-col>
            <b-col cols="1" />
            <b-col cols="5">
              <div
                v-if="disableBuildTech"
                class="ml-1 mt-1"
              >
                {{ dataOperations.approvedBy }}
              </div>
              <b-input-group
                v-if="dataOperations.status !== 'Shipped'"
                class="input-group-merge"
              >
                <b-form-select
                  v-model="inputApprovedTech"
                  :options="approverTechs"
                  :disabled="disableApproverTech"
                  text-field="fullName"
                  value-field="UID"
                />
                <b-input-group-append
                  v-if="!disableApproverTech"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    @click="inputApprovedTech = 'TECH-00000040'"
                  />
                </b-input-group-append>
              </b-input-group>
              <div class="font-small-2 ml-1">
                Approved By
              </div>
              <div
                v-if="!disableApproverTech && inputApprovedTech !== 'TECH-00000040'"
                align="center"
              >
                <b-button
                  v-if="dataOperations.status === 'Ready to Approve'"
                  variant="outline-primary"
                  @click="dataOperations.status = 'Ready To Ship'"
                >
                  APPROVE
                </b-button>
                <b-button
                  v-if="dataOperations.status === 'Ready To Ship'"
                  variant="outline-primary"
                  @click="dataOperations.status = 'Ready to Approve'"
                >
                  UNAPPROVE
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
      <b-card class="mt-n1 px-3">
        <b-overlay
          :show="searching"
          rounded="sm"
        >
          <b-row>
            <b-col
              lg="5"
              cols="12"
              class="mb-2"
            >
              <h5>Product Serials</h5>
              <b-row
                v-for="(serial, counter1) in serials"
                :key="serial.serialNumber"
              >
                <b-col cols="6">
                  <b-form-input
                    v-model="serials[counter1].serialNumber"
                    type="text"
                    debounce="3000"
                    :disabled="disableSerials"
                    @update="saveSerialP(counter1)"
                  />
                  <div class="font-small-2 ml-1">
                    Serial Number
                  </div>
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    v-model="serials[counter1].model"
                    type="text"
                    debounce="3000"
                    list="models"
                    :disabled="disableSerials"
                    @update="saveSerialP(counter1)"
                  />
                  <datalist id="models">
                    <option
                      v-for="lineItem in dataInvoiceLineItems.results"
                      :key="lineItem.itemRef_FullName"
                    >
                      {{ lineItem.itemRef_FullName }}
                    </option>
                  </datalist>
                  <div class="font-small-2 ml-1">
                    Model
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1" />
            <b-col
              lg="5"
              cols="12"
              class="mb-2"
            >
              <h5>Other Serials</h5>

              <b-row
                v-for="(serial2, counter2) in serials2"
                :key="serial2.serialNumber"
              >
                <b-col cols="6">
                  <b-form-input
                    v-model="serials2[counter2].serialNumber"
                    type="text"
                    debounce="3000"
                    :disabled="disableSerials"
                    @update="saveSerialA(counter2)"
                  />
                  <div class="font-small-2 ml-1">
                    Serial Number
                  </div>
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    v-model="serials2[counter2].model"
                    type="text"
                    debounce="3000"
                    list="models"
                    :disabled="disableSerials"
                    @update="saveSerialA(counter2)"
                  />
                  <datalist
                    v-if="dataInvoiceLineItems.results"
                    id="models"
                  >
                    <option
                      v-for="lineItem in dataInvoiceLineItems.results"
                      :key="lineItem.itemRef_FullName"
                    >
                      {{ lineItem.itemRef_FullName }}
                    </option>
                  </datalist>
                  <div class="font-small-2 ml-1">
                    Model
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </section>
    <section class="mt-n1">
      <invoice
        v-if="dataInvoice.queryCount || invoiceSearching"
        :table-data="dataInvoiceLineItems"
        :invoice-data="dataInvoice"
      />
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BProgress,
  BProgressBar,
  BRow,
  BCol,
  BOverlay,
  BForm,
  BAvatar,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Invoice from '../components/_invoice/InvoiceCard.vue'

export default {
  components: {
    BCard,
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BOverlay,
    BForm,
    BAvatar,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    Invoice,
  },
  data() {
    return {
      currentUser: this.$auth.user.name,
      value: '',
      noOfProducts: 1,
      timer: 0,
      timer2: 0,
      barValue: 0,
      status1: 'light-primary',
      status2: 'light-primary',
      status3: 'light-primary',
      status4: 'light-primary',
      status5: 'light-primary',
      searching: true,
      searchingInvoice: true,
      invoiceSearching: true,
      message: '',
      inputInvoiceNumber: '',
      inputBuildTech: '',
      inputApprovedTech: '',
      inputNumProducts: 0,
      invoiceSearch: '',
      disableSerials: false,
      disableApproverTech: true,
      disableBuildTech: false,
      dataInvoice: {},
      dataInvoiceLineItems: {},
      dataSerials: {},
      serials: [
        {
          ID: 0,
          UID: '',
          timeCreated: '',
          timeModified: '',
          invoiceID: '',
          addedBy: '',
          active: true,
          serialNumber: '',
          model: '',
          pid: '',
        },
      ],
      serials2: [
        {
          ID: 0,
          UID: '',
          timeCreated: '',
          timeModified: '',
          invoiceID: '',
          addedBy: '',
          active: true,
          serialNumber: '',
          model: '',
          pid: '',
        },
      ],
      dataTechs: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ID: 0,
            UID: '',
            fullName: '',
            role: '',
            type: '',
          },
        ],
      },
      dataOperations: {
        ID: 0,
        UID: '',
        timeCreated: '',
        timeModified: '',
        updatedBy: '',
        invoiceID: '',
        approvedByID: '',
        builtByID: '',
        buildCompleteDate: '',
        buildComplete: false,
        noOfUnits: 0,
        serialNumbers: '',
        substitution: false,
        dropShip: false,
        status: '',
        shipDate: '',
        shipDateActual: '',
        invoiceDate: '',
        invoiceProduct: '',
        isShipped: false,
        isDelivered: false,
        tracking: '',
        deliverDateActual: '',
        builtBy: '',
        approvedBy: '',
      },
    }
  },
  computed: {
    approverTechs() {
      if (this.dataTechs.queryCount > 0) {
        return this.dataTechs.results.filter(item => item.type.toLowerCase().startsWith('approver'))
      }
      return null
    },
    filledSerials() {
      return this.serials.filter(item => item.serialNumber)
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  watch: {
    async inputBuildTech(val) {
      if (!this.searching) {
        if (val !== this.dataOperations.builtBy) {
          this.disableBuildTech = true
          this.dataOperations.builtBy = val
          await this.$http
            .get(
              `https://asi-api2.azure-api.net/techs/assigned/update?id=${this.inputInvoiceNumber}&builtBy=${val}&updatedBy=${this.currentUser}&key=1ea49cfa0a144e7daf97f1e42601ca05`,
            )
            .then(result => { this.dataOperations = result.data })
          this.disableBuildTech = false
        }
        if (val === 'TECH-00000040' && this.dataOperations.status !== 'Not Assigned') {
          this.dataOperations.status = 'Not Assigned'
        }
        if (val !== 'TECH-00000040' && val && this.dataOperations.status === 'Not Assigned') {
          this.dataOperations.status = 'Ready to Build'
        }
      }
    },
    async inputApprovedTech(val) {
      if (!this.searching) {
        this.disableApproverTech = true
        if (val !== this.dataOperations.approvedBy) {
          this.dataOperations.approvedBy = val
          await this.$http
            .get(
              `https://asi-api2.azure-api.net/techs/approver/update?id=${this.inputInvoiceNumber}&approver=${val}&updatedBy=${this.currentUser}&key=1ea49cfa0a144e7daf97f1e42601ca05`,
            )
            .then(result => { this.dataOperations = result.data })
        }
        if (val === 'TECH-00000040' && this.dataOperations.status !== 'Ready to Approve') {
          this.dataOperations.status = 'Ready to Approve'
        }
        this.disableApproverTech = false
      }
    },
    // eslint-disable-next-line func-names
    'dataOperations.status': async function (val, oldVal) {
      if (val !== oldVal && !this.searching) {
        this.status1 = 'light-primary'
        this.status2 = 'light-primary'
        this.status3 = 'light-primary'
        this.status4 = 'light-primary'
        this.status5 = 'light-primary'
        await this.$http
          .get(
            `https://asi-api2.azure-api.net/techs/status/update?id=${this.inputInvoiceNumber}&updatedBy=${this.currentUser}&status=${val}&key=1ea49cfa0a144e7daf97f1e42601ca05`,
          )
          .then(result => { this.dataOperations = result.data })
      }
      if (val.toLowerCase().includes('ready to build') || val.toLowerCase().includes('not assigned')) {
        this.status1 = 'primary'
        this.disableBuildTech = false
        this.disableApproverTech = true
      }
      if (val.toLowerCase().includes('building')) {
        this.status2 = 'primary'
        this.disableBuildTech = false
        this.disableApproverTech = true
      }
      if (val.toLowerCase().includes('approve')) {
        this.status3 = 'primary'
        this.disableBuildTech = false
        this.disableApproverTech = false
      }
      if (val.toLowerCase().includes(' ship')) {
        this.status4 = 'primary'
        await this.getInvoiceOps()
        this.disableBuildTech = true
        this.disableApproverTech = false
      }
      if (val.toLowerCase().includes('shipped')) {
        this.status1 = 'light-primary'
        this.status5 = 'primary'
        this.disableBuildTech = true
        this.disableApproverTech = true
      }
    },
    serials(val) {
      if (val[val.length - 1].ID !== 0) {
        this.addSerial()
      }
    },
    serials2(val) {
      if (val[val.length - 1].ID !== 0) {
        this.addSerial2()
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) {
        this.barValue = 0
      }
      this.barValue += 5
    }, 600)
  },
  async created() {
    this.inputInvoiceNumber = this.$route.params.id
    this.invoiceSearch = this.$route.params.id
    this.searching = true
    this.resetSerials()
    this.getTechs()
    await this.getInvoiceOps()
    await this.getSerials()
    this.setInitialSerials()
    this.searching = false
    await this.searchInvoice()
    await this.searchInvoiceLines()
    this.invoiceSearching = false
  },
  methods: {
    addSerial() {
      this.serials.push({
        ID: 0,
        UID: '',
        timeCreated: '',
        timeModified: '',
        invoiceID: '',
        addedBy: '',
        active: true,
        serialNumber: '',
        model: '',
        type: 'product',
      })
    },
    addSerial2() {
      this.serials2.push({
        ID: 0,
        UID: '',
        timeCreated: '',
        timeModified: '',
        invoiceID: '',
        addedBy: '',
        active: true,
        serialNumber: '',
        model: '',
        type: 'accessory',
      })
    },
    setInitialSerials() {
      if (this.serials[0].ID === 0) {
        while (this.serials.length < 5) {
          this.addSerial()
        }
      }
      if (this.serials2[0].ID === 0 && this.serials[0].ID === 0) {
        while (this.serials2.length < 5) {
          this.addSerial2()
        }
      }
    },
    async saveSerialP(counter) {
      this.disableSerials = true
      this.serials[counter].invoiceID = this.inputInvoiceNumber
      await this.$http
        .post(
          `https://asi-api2.azure-api.net/techs/invoice/${this.inputInvoiceNumber}/serials?key=1ea49cfa0a144e7daf97f1e42601ca05`,
          this.serials[counter],
        )
        .then(response => {
          console.log(response)
          this.serials[counter] = response.data
          this.disableSerials = false
        })
      if (this.serials[this.serials.length - 1].ID !== 0) {
        this.addSerial()
      }
    },
    async saveSerialA(counter) {
      this.disableSerials = true
      this.serials2[counter].invoiceID = this.inputInvoiceNumber
      await this.$http
        .post(
          `https://asi-api2.azure-api.net/techs/invoice/${this.inputInvoiceNumber}/serials?key=1ea49cfa0a144e7daf97f1e42601ca05`,
          this.serials2[counter],
        )
        .then(response => {
          console.log(response)
          this.serials2[counter] = response.data
          this.disableSerials = false
        })
      if (this.serials2[this.serials2.length - 1].ID !== 0) {
        this.addSerial2()
      }
    },
    async getInvoiceOps() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/techs/invoice/${this.inputInvoiceNumber}?version=2&key=1ea49cfa0a144e7daf97f1e42601ca05`,
        )
        .then(response => {
          this.dataOperations = response.data
          this.inputBuildTech = this.dataOperations.builtByID
          this.inputApprovedTech = this.dataOperations.approvedByID
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getSerials() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/techs/invoice/${this.inputInvoiceNumber}/serials?version=2&key=1ea49cfa0a144e7daf97f1e42601ca05`,
        )
        .then(response => {
          this.dataSerials = response.data
          if (
            this.dataSerials.results.filter(
              item => !item.type || item.type.toLowerCase().startsWith('product'),
            ).length > 0
          ) {
            this.serials = this.dataSerials.results.filter(
              item => !item.type || item.type.toLowerCase().startsWith('product'),
            )
          }
          if (
            this.dataSerials.results.filter(item => item.type.toLowerCase().startsWith('accessory')).length > 0
          ) {
            this.serials2 = this.dataSerials.results.filter(item => item.type.toLowerCase().startsWith('accessory'))
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getTechs() {
      await this.$http
        .get(
          'https://asi-api2.azure-api.net/techs/getTechnicians?key=1ea49cfa0a144e7daf97f1e42601ca05',
        )
        .then(response => {
          this.dataTechs = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async openInvoice() {
      if (this.invoiceSearch !== this.inputInvoiceNumber) {
        if (this.invoiceSearch.length > 3 && this.invoiceSearch.length < 7) {
          this.message = 'searching'
          let invoiceLookup = {}
          await this.$http
            .get(
              `https://asi-api2.azure-api.net/invoice/check/${this.invoiceSearch}?key=55d85562ab5f47dba870ae9caf584081`,
            )
            .then(res => {
              invoiceLookup = res.data
            })
          if (invoiceLookup.queryCount > 0) {
            this.$router.push({
              name: 'techops-edit',
              params: { id: this.invoiceSearch },
            })
            this.inputInvoiceNumber = this.$route.params.id
            this.invoiceSearch = this.$route.params.id
            this.searching = true
            this.resetSerials()
            this.getTechs()
            await this.getInvoiceOps()
            await this.getSerials()
            this.setInitialSerials()
            this.message = ''
            this.searching = false
            await this.searchInvoice()
            await this.searchInvoiceLines()
            this.invoiceSearching = false
          } else this.message = 'please enter a valid invoice number'
        } else {
          this.message = 'please enter a valid invoice number'
        }
      }
    },
    async searchInvoice() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/invoice/view/${this.inputInvoiceNumber}?version=2&key=55d85562ab5f47dba870ae9caf584081`,
        )
        .then(response => {
          this.dataInvoice = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async searchInvoiceLines() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/invoice/lineitems/${this.dataInvoice.result.txnID}?version=2&key=55d85562ab5f47dba870ae9caf584081`,
        )
        .then(response => {
          this.dataInvoiceLineItems = response.data
          this.inputNumProducts = this.dataInvoiceLineItems.results[0].quantity
        })
        .catch(error => {
          console.log(error)
          this.dataInvoiceLineItems.queryCount = null
        })
      this.searchingInvoice = false
    },
    resetSerials() {
      this.serials = [
        {
          ID: 0,
          UID: '',
          timeCreated: '',
          timeModified: '',
          invoiceID: '',
          addedBy: '',
          active: true,
          serialNumber: '',
          model: '',
          pid: '',
        },
      ]
      this.serials2 = [
        {
          ID: 0,
          UID: '',
          timeCreated: '',
          timeModified: '',
          invoiceID: '',
          addedBy: '',
          active: true,
          serialNumber: '',
          model: '',
          pid: '',
        },
      ]
    },
    filteredCounts(filterName) {
      return this.invoices.results.filter(item => item.buildStatus.toLowerCase().includes(filterName)).length
    },
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31
        && (charCode < 48 || charCode > 57)
        && charCode !== 46
      ) {
        evt.preventDefault()
      }
      return true
    },
  },
}
</script>
<style>
.diagonal-box {
  background-color: #013a6b;
  background-image: -webkit-linear-gradient(30deg, #013a6b 50%, #004e95 50%);
}
.box-content-top {
  max-width: 50%;
  max-height: 50%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
}
.box-content-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 50%;
  max-height: 50%;
  margin: auto;
  text-align: center;
}
</style>
