<template>
  <b-card-actions
    action-collapse
    title="Invoice Data"
    no-body
  ><b-overlay
    :show="show"
    rounded="sm"
  >
    <b-card-body class="font-medium-1 pt-0">
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <div v-if="invoiceData.result.timeCreated">
            {{ invoiceData.result.timeCreated | moment("MMMM Do, YYYY") }}
          </div>
          <div
            v-if="invoiceData.result.timeCreated"
            class="font-small-1 mb-1"
          >
            Invoice created {{ invoiceData.result.timeCreated | moment("from") }}
          </div>
          <div v-if="invoiceData.result.subtotal">
            {{ invoiceData.result.subtotal | toCurrency() }}
          </div>
          <div
            v-if="invoiceData.result.subtotal"
            class="font-small-1 mb-1"
          >
            Order Amount
          </div>
          <div v-if="invoiceData.result.salesRepRef_FullName">
            {{ invoiceData.result.salesRepRef_FullName }}
          </div>
          <div
            v-if="invoiceData.result.salesRepRef_FullName"
            class="font-small-1 mb-1"
          >
            Sales Rep
          </div>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <div v-if="invoiceData.result.other">
            {{ invoiceData.result.other }}
          </div>
          <div
            v-if="invoiceData.result.other"
            class="font-small-1 mb-1"
          >
            Tracking Number (invoice)
          </div>
          <div v-if="invoiceData.result.memo">
            {{ invoiceData.result.memo }}
          </div>
          <div
            v-if="invoiceData.result.memo"
            class="font-small-1 mb-1"
          >
            Memo
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        ><b-row>
          <b-col
            v-if="invoiceData.result.customerRef_FullName != 'Amazon Bulk Invoices'"
            cols="6"
          >
            <div class="font-small-1">
              Billing Data
            </div>
            <div v-if="invoiceData.result.billAddress_Addr1">
              {{ invoiceData.result.billAddress_Addr1 }}
            </div>
            <div v-if="invoiceData.result.billAddress_Addr2">
              {{ invoiceData.result.billAddress_Addr2 }}
            </div>
            <div v-if="invoiceData.result.billAddress_Addr3">
              {{ invoiceData.result.billAddress_Addr3 }}
            </div>
            <div v-if="invoiceData.result.billAddress_Addr4">
              {{ invoiceData.result.billAddress_Addr4 }}
            </div>
            <div v-if="invoiceData.result.billAddress_Addr5">
              {{ invoiceData.result.billAddress_Addr5 }}
            </div>
            <div v-if="invoiceData.result.billAddress_City && invoiceData.result.billAddress_State && invoiceData.result.billAddress_PostalCode">
              {{ invoiceData.result.billAddress_City }}, {{ invoiceData.result.billAddress_State }} {{ invoiceData.result.billAddress_PostalCode }}
            </div>
          </b-col>
          <b-col cols="6">
            <div
              v-if="invoiceData.result.memo"
              class="font-small-1"
            >
              Shipping Data
            </div>
            <div v-if="invoiceData.result.shipAddress_Addr1">
              {{ invoiceData.result.shipAddress_Addr1 }}
            </div>
            <div v-if="invoiceData.result.shipAddress_Addr2">
              {{ invoiceData.result.shipAddress_Addr2 }}
            </div>
            <div v-if="invoiceData.result.shipAddress_Addr3">
              {{ invoiceData.result.shipAddress_Addr3 }}
            </div>
            <div v-if="invoiceData.result.shipAddress_Addr4">
              {{ invoiceData.result.shipAddress_Addr4 }}
            </div>
            <div v-if="invoiceData.result.shipAddress_Addr5">
              {{ invoiceData.result.shipAddress_Addr5 }}
            </div>
            <div v-if="invoiceData.result.shipAddress_City && invoiceData.result.shipAddress_State && invoiceData.result.shipAddress_PostalCode">
              {{ invoiceData.result.shipAddress_City }}, {{ invoiceData.result.shipAddress_State }} {{ invoiceData.result.shipAddress_PostalCode }}
            </div>
          </b-col>
        </b-row>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      :items="tableData.results"
      responsive
      :fields="fields"
      class="mb-0 table-striped"
    >
      <template #cell(desc)="data">
        <div class="d-flex align-items-center">
          <div style="width: 100%">
            <div
              style="
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  max-width: 750px;
                  width: auto;
                "
            >
              <span v-html="changeNewLine(data.item.desc)" />
            </div>
          </div>
        </div>
      </template>
      <template #cell(quantity)="data">
        <div class="text-center">
          {{ data.item.quantity }}
        </div>
      </template>
      <template #cell(rate)="data">
        <div class="text-center">
          <span
            v-if="data.item.rate < 0"
            class="text-danger"
          >
            -{{ (data.item.rate * -1) | toCurrency() }}
          </span>
          <span v-if="data.item.rate > 0">
            {{ data.item.rate | toCurrency() }}
          </span>
        </div>
      </template>
      <template #cell(amount)="data">
        <div class="text-center">
          <span
            v-if="data.item.amount < 0"
            class="text-danger"
          >
            -{{ (data.item.amount * -1) | toCurrency() }}
          </span>
          <span v-if="data.item.amount > 0">
            {{ data.item.amount | toCurrency() }}
          </span>
        </div>
      </template>
    </b-table>
  </b-overlay>
  </b-card-actions>
</template>

<script>
import {
  BTable, BOverlay, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BTable,
    BOverlay,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    tableData: {
      type: Object,
      default: () => [],
    },
    invoiceData: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      fields: [
        { key: 'itemRef_FullName', label: 'ITEM' },
        { key: 'desc', label: 'DESCRIPTION' },
        { key: 'quantity', label: 'QUANTITY' },
        { key: 'rate', label: 'EACH' },
        { key: 'amount', label: 'SUBTOTAL' },
      ],
    }
  },
  watch: {
    tableData(val) {
      if (val.queryCount === 0 || val.queryCount === null) {
        this.show = true
      }
      if (val.queryCount > 0) {
        this.show = false
      }
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    changeNewLine(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
<style>
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
</style>
